import React, { useState, useEffect } from 'react';
import '../styles/summary.scss';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import ViewIcon from '../icons/viewIcon.svg';
import axios from 'axios';
const Summary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [projectData, setProjectData] = useState(null);
    const { categoryId } = location.state || {};
    const { title } = useParams();
    const token = localStorage.getItem('token');
    const countryMapping = {
        BN: 'Brunei',
        ID: 'Indonesia',
        KH: 'Cambodia',
        LA: 'Laos',
        MM: 'Myanmar',
        MY: 'Malaysia',
        PH: 'Philippines',
        SG: 'Singapore',
        TH: 'Thailand',
        VN: 'Vietnam'
    };
    useEffect(() => {
        const fetchProjectData = async () => {
            const apiURL = process.env.REACT_APP_BASE_API_URL;
            try {
                const response = await axios.get(`${apiURL}/scores/category/${categoryId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        limit: 30
                    }
                });
                setProjectData(response.data);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };
        if (token) {
            fetchProjectData();
        }
    }, [token, categoryId]);
    // console.log(projectData);

    const handleLogoutModalOpen = () => {
        setIsLogoutModalOpen(true);
    }
    const handleLogoutModalClose = () => {
        setIsLogoutModalOpen(false);
    }
    const handleLogoutConfirm = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    const formatTitle = (title) => {
        if (title === 'digital-start-up') {
            return 'Digital Start-up';
        } else {
            return title
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    };
    const formatCategory = (category) => {
        if (!category) return '';
        return category
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const formatTitleForURL = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    }
    const handleBackCategory = () => {
        navigate(`/award-categories/${formatTitleForURL(title)}`, { state: { categoryId: categoryId } })
    }
    const selectProject = (itemId) => {
        navigate(`/award-categories/${title}/${itemId}`, { state: { summaryStatus: true } })
    }
    return (
        <>
            <div className="container-summary">
                <div className="summary">
                    <div className="content">
                        <section className="top">
                            <div className="top-logo">
                                <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
                            </div>
                            <div className="top-breadcrumb">
                                <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
                                <span> / </span>
                                <Link to="/award-categories" className="bread-award-categories">Award Categories</Link>
                                <span> / </span>
                                <span onClick={handleBackCategory} className="bread-category">{formatCategory(title)}</span>
                                <span> / </span>
                                <Link to={`/award-categories/${title}/summary`} className="bread-summary">Summary</Link>
                            </div>
                            <div className="top-title">
                                <span className="H1">{formatTitle(title)}</span>
                            </div>
                            <div className="top-desc">
                                <p className="Body">
                                    Summary of successfully submitted projects
                                </p>
                            </div>
                            <div className="top-btn" onClick={handleBackCategory}>
                                <button className="Body">Back</button>
                            </div>
                        </section>
                        <section className="main">
                            <table className="Body">
                                <thead>
                                    <tr>
                                        <th className="topic-id">Team ID</th>
                                        <th className="topic-tname">Team Name</th>
                                        <th className="topic-title">Title</th>
                                        <th className="topic-country">Country</th>
                                        <th className="topic-score">Total Score</th>
                                        <th className="topic-info">Team Info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectData?.items?.map((item) => (
                                        <tr key={item.team_id}>
                                            <td>{item.team_id}</td>
                                            <td className="desc-tname">{item.team_name}</td>
                                            <td className="desc-title">{item.title}</td>
                                            <td>{countryMapping[item.country]}</td>
                                            <td>
                                                {item.score === 0
                                                    ? '00'
                                                    : item.score % 1 === 0
                                                        ? item.score < 8
                                                            ? `0${item.score}`
                                                            : item.score
                                                        : item.score.toFixed(2)
                                                }/10
                                            </td>
                                            <td>
                                                <button onClick={() => selectProject(item.id)} className="view-btn">
                                                    <img src={ViewIcon} width={16} height={16} alt='viewIcon' />
                                                    <span className="Subtitle">View</span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
            <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
        </>
    )
}

export default Summary;