import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { mdiSquareEditOutline } from '@mdi/js';
import Icon from '@mdi/react';
import '../styles/submissionTable.scss';
import { useNavigate } from 'react-router-dom';
const SubmissionTable = () => {
  const navigate = useNavigate();
  // const userData = JSON.parse(localStorage.getItem('user'));
  // const userRole = userData.role;
  const token = localStorage.getItem('token');
  
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const fetchProjects = async () => {
      const apiURL = process.env.REACT_APP_BASE_API_URL;
      try {
        const response = await axios.get(`${apiURL}/projects`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, [token]);
  //console.log(projects);
  
  const editProject = (project) => {
    if (!project) return;
    navigate(`/overview/edit-submission-form/${project.id}`)
}

  const categories = [
    { id: 1, name: 'Public Sector' },
    { id: 2, name: 'Private Sector' },
    { id: 3, name: 'Digital Inclusivity' },
    { id: 4, name: 'Digital Content' },
    { id: 5, name: 'Digital Start-up' },
    { id: 6, name: 'Digital Innovation' }
  ];
  const topic = [
    'Awards Categories',
    'Team ID',
    'Team Name',
    'Project Name',
    'Status',
    'Edit'
  ];

  return (
    <div className="submission-table">
      <table>
        <thead>
          <tr>
            {topic.map((topic, index) => (
              <th className={
                `Body ${index === 1 ? 'width-135' : 
                index === 2 ? 'width-368' : 
                index === 3 ? 'width-368' : 
                index === 4 ? 'width-98' : 
                index === 5 ? 'width-68' : ''}`
                } key={index}>{topic}</th>
            ))}
          </tr>
        </thead>
        <tbody>
        {categories.map((category, index) => (
  <React.Fragment key={index}>
    {[0, 1, 2].map((rowIndex) => {
      const project = projects.find(project => project.category === category.id && project.items && project.items.length > rowIndex);
      const item = project ? project.items[rowIndex] : null;
      return (
        <tr key={`${index}-${rowIndex}`} className={`row-${rowIndex}`}>
          {rowIndex === 0 && <td rowSpan="3" className="category Body">{category.name}</td>}
          <td className="Body">{item ? item.team_id : '-'}</td>
          <td className="Body team-name">{item ? item.team_name : '-'}</td>
          <td className="Body project-name">{item ? item.title : '-'}</td>
          <td className={item ? "Subtitle submitted" : "Body"}>{item ? 'Submitted' : '-'}</td>
          <td>
            <button 
            onClick={() => editProject(item)}
            className="editBtn"
            >
              <Icon path={mdiSquareEditOutline} className={item ? 'edit' : 'disabled'} size={0.7} />
            </button>
          </td>
        </tr>
      );
    })}
  </React.Fragment>
))}
        </tbody>
      </table>
    </div>
  );
};

export default SubmissionTable;