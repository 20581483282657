export const isEnglishText = (text) => {
    const englishTextRegex = /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~\s]*$/;
    return englishTextRegex.test(text);
  };
  export const isValidYouTubeURL = (url) => {
    const vidURLRegex = /^(https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]+|https?:\/\/(www\.)?(drive\.google\.com|vimeo\.com)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*(\?[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+(&[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)*)?)$/i;
    return vidURLRegex.test(url);
};

  export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };