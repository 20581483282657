import React, { useState, useEffect } from 'react';
import '../styles/finalSummary.scss';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import ViewIcon from '../icons/viewIcon.svg';
import axios from 'axios';
const FinalSummary = () => {
  document.title = 'Summary Finalist | Judging.AseanDigitalAwards2025';
    const navigate = useNavigate();
    const location = useLocation();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [projectData, setProjectData] = useState(null);
    const { categoryId } = location.state || {};
    const token = localStorage.getItem('token');
    const countryMapping = {
        BN: 'Brunei',
        ID: 'Indonesia',
        KH: 'Cambodia',
        LA: 'Laos',
        MM: 'Myanmar',
        MY: 'Malaysia',
        PH: 'Philippines',
        SG: 'Singapore',
        TH: 'Thailand',
        VN: 'Vietnam'
    };
    const categoryMapping = {
        1: 'Public Sector',
        2: 'Private Sector',
        3: 'Digital Inclusivity',
        4: 'Digital Content',
        5: 'Digital Start-up',
        6: 'Digital Innovation',
    };
    useEffect(() => {
        const fetchProjectData = async () => {
            const apiURL = process.env.REACT_APP_BASE_API_URL;
            try {
                const response = await axios.get(`${apiURL}/projects`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setProjectData(response.data);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };
        if (token) {
            fetchProjectData();
        }
    }, [token, categoryId]);
    // console.log(projectData);

    const handleLogoutModalOpen = () => {
        setIsLogoutModalOpen(true);
    }
    const handleLogoutModalClose = () => {
        setIsLogoutModalOpen(false);
    }
    const handleLogoutConfirm = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    const formatTitleForURL = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    }
    const handleBackCategory = () => {
        navigate(`/award-categories`)
    }
    const selectProject = (title, itemId) => {
        navigate(`/award-categories/${title}/${itemId}`, { state: { finalSummaryStatus: true } })
    }
    return (
        <>
            <div className="container-final-summary">
                <div className="final-summary">
                    <div className="content">
                        <section className="top">
                            <div className="top-logo">
                                <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
                            </div>
                            <div className="top-breadcrumb">
                                <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
                                <span> / </span>
                                <Link to="/award-categories" className="bread-award-categories">Award Categories</Link>
                                <span> / </span>
                                <Link to={`/award-categories/final-summary`} className="bread-summary">Summary</Link>
                            </div>
                            <div className="top-title">
                                <span className="H1">Finalists</span>
                            </div>
                            <div className="top-desc">
                                <p className="Body">
                                    Summary of 18 finalist teams
                                </p>
                            </div>
                            <div className="top-btn" onClick={handleBackCategory}>
                                <button className="Body">Back</button>
                            </div>
                        </section>
                        <section className="main">
                            {projectData?.map((item) => (
                                <>
                                    <h1 className="Title-1">{categoryMapping[item.category]}</h1>
                                    <table key={item.category} className="Body">
                                        <thead>
                                            <tr>
                                                <th className="topic-id">Team ID</th>
                                                <th className="topic-tname">Team Name</th>
                                                <th className="topic-title">Title</th>
                                                <th className="topic-country">Country</th>
                                                <th className="topic-score">Total Score</th>
                                                <th className="topic-info">Team Info</th>
                                                <th className="topic-status">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.items.map((project) => (
                                                <tr key={project.id}>
                                                    <td>{project.team_id}</td>
                                                    <td className="desc-tname">{project.team_name}</td>
                                                    <td className="desc-title">{project.title}</td>
                                                    <td>{countryMapping[project.country]}</td>
                                                    <td>
                                                        {project.score === 0
                                                            ? '0.00'
                                                            : project.score % 1 === 0
                                                                ? project.score < 8
                                                                    ? `0${project.score}`
                                                                    : project.score
                                                                : project.score?.toFixed(2)
                                                        }/10
                                                    </td>
                                                    <td>
                                                        <button onClick={() => selectProject(formatTitleForURL(categoryMapping[item.category]), project.id)} className="view-btn">
                                                            <img src={ViewIcon} width={16} height={16} alt='viewIcon' />
                                                            <span className="Subtitle">View</span>
                                                        </button>
                                                    </td>
                                                    <td className={`desc-status ${project.status === 'scored' ? 'desc-status-done' : 'desc-status-undone'}`}>{project.status === 'scored' ? 'Done' : 'In progress'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            ))}
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
            <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
        </>
    )
}

export default FinalSummary;