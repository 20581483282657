import React, {useState, useEffect} from "react";
import { Tabs } from "antd";
import JudgingCriteria from './judgingCriteria';
import StrategyPlanning from './strategyPlanning';
import Operation from './operation';
import Presentation from './presentation';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/scoring/tab.scss'
const TabScoring = () => {
    const navigate = useNavigate();
    const [criteria, setCriteria] = useState(null);
    const [currentScore, setCurrentScore] = useState(null);
    const token = localStorage.getItem('token');
    const { projectId, title } = useParams();
    const [activeKey, setActiveKey] = useState("1");
    const [validationErrors, setValidationErrors] = useState({});
    const apiURL = process.env.REACT_APP_BASE_API_URL;
    const [formData, setFormData] = useState({
      project_id: projectId,
      scores: [],
    });
    const formatCategory = (category) => {
      if (!category) return '';
      if (category === 'digital-start-up') {
        return 'Digital Start-up';
      }
      return category
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    };
    const catTitle = formatCategory(title)
    const titleToIdMap = {
        'Public Sector': 1,
        'Private Sector': 2,
        'Digital Inclusivity': 3,
        'Digital Content': 4,
        'Digital Start-up': 5,
        'Digital Innovation': 6,
      };
    const criteriaId = titleToIdMap[catTitle];
    useEffect(() => {
        const fetchCriteria = async () => {
          try {
            const response = await axios.get(`${apiURL}/scores/criteria/${criteriaId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              },
            });
            setCriteria(response.data);
            // console.log(response.data);
          } catch (error) {
            console.error('Error fetching criteria:', error);
          }
        };
        fetchCriteria();
      }, [token, criteriaId, apiURL]);
      useEffect(() => {
        const fetchCurrentScore = async () => {
          try {
            const response = await axios.get(`${apiURL}/scores/current/${projectId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            setCurrentScore(response.data);
          } catch (error) {
            console.error('Error fetching criteria:', error);
          }
        };
        fetchCurrentScore();
      }, [projectId, token, apiURL]);
      const handleNext = () => {
        setActiveKey((prevKey) => (parseInt(prevKey) + 1).toString());
      };
    
      const handleBack = () => {
        setActiveKey((prevKey) => (parseInt(prevKey) - 1).toString());
      };
      const handleFormDataChange = (sectionId, attributeId, score, reason = null) => {
        // console.log(`sectionId: ${sectionId}, attributeId: ${attributeId}, score: ${score}, reason: ${reason}`);
        setFormData(prevData => {
          const sectionIndex = prevData.scores.findIndex(section => section.id === sectionId);
          if (sectionIndex !== -1) {
            const attributeIndex = prevData.scores[sectionIndex].attributes.findIndex(attr => attr.id === attributeId);
            if (attributeIndex !== -1) {
              prevData.scores[sectionIndex].attributes[attributeIndex].score = score;
            } else {
              prevData.scores[sectionIndex].attributes.push({ id: attributeId, score });
            }
            prevData.scores[sectionIndex].reason = reason;
          } else {
            prevData.scores.push({ id: sectionId, reason, attributes: [{ id: attributeId, score }] });
          }
          // console.log(`Updated formData:`, prevData);
          return { ...prevData };
        });
      };
      const catIdMap = {
        'public-sector': 1,
        'private-sector': 2,
        'digital-inclusivity': 3,
        'digital-content': 4,
        'digital-start-up': 5,
        'digital-innovation': 6,
      };
      const validateFormData = () => {
        const requiredSections = [1, 2, 3]; // IDs for StrategyPlanning, Operation, and Presentation
        const errors = {};
        let firstError = null;
        requiredSections.forEach(sectionId => {
          const section = formData.scores.find(section => section.id === sectionId);
          const criteriaSection = criteria.criterias.find(crit => crit.id === sectionId);
          if (!section || section.attributes.length !== criteriaSection.attributes.length) {
            errors[sectionId] = true;
            if (!firstError) {
              firstError = { sectionId, attributeId: null };
            }
            return;
          }
          section.attributes.forEach(attr => {
            if (!attr.score) {
              if (!errors[sectionId]) errors[sectionId] = {};
              errors[sectionId][attr.id] = true;
              if (!firstError) {
                firstError = { sectionId, attributeId: attr.id };
              }
            }
          });
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
      };
      const handleSubmit = async () => {
        try {
          const response = await axios.post(`${apiURL}/scores`, formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          // console.log('Submission successful:', response.data);
          const successStatus = response.data.success
          const categoryId = catIdMap[title];
          // console.log('Navigating to:', `/award-categories/${title}`, { state: { categoryId, successStatus } });
          navigate(`/award-categories/${title}`, { state: { categoryId, successStatus } });
        } catch (error) {
          console.error('Error submitting data:', error);
        }
      };
      const handleEdit = () => {
        setActiveKey("2");
        if (currentScore) {
          currentScore.data.scores.forEach(section => {
            section.attributes.forEach(attribute => {
              handleFormDataChange(section.id, attribute.id, attribute.score, section.reason);
            });
          });
        }
      };
      const handleEditSubmit = async () => {
        try {
          const response = await axios.put(`${apiURL}/scores`, formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          const editSuccess = response.data.success;
          const categoryId = catIdMap[title];
          navigate(`/award-categories/${title}`, { state: { categoryId, successStatus: editSuccess, isEdit: true } });
        } catch (error) {
          console.error('Error updating data:', error);
        }
      };
    const items = [
        {
            key: '1',
            label: <span className="Subtitle">Judging Criteria</span>,
            children: <JudgingCriteria title={catTitle} scored={currentScore} criteria={criteria} handleNext={handleNext} handleEdit={handleEdit} />
        },
        {
            key: '2',
            label: <span className="Subtitle">{catTitle==='Digital Start-up' ? <span>Strategy Planning<br/>Unique Selling Proposition</span>:'Strategy Planning'}</span>,
            children: <StrategyPlanning title={catTitle} scored={currentScore} criteria={criteria} handleNext={handleNext} handleBack={handleBack} handleFormDataChange={handleFormDataChange} currentScore={currentScore} handleEdit={handleEdit} validationErrors={validationErrors}/>
        },
        {
            key: '3',
            label: <span className="Subtitle">{criteria?.criterias[1].name}</span>,
            children: <Operation title={catTitle} scored={currentScore} criteria={criteria} handleNext={handleNext} handleBack={handleBack} handleFormDataChange={handleFormDataChange} currentScore={currentScore} handleEdit={handleEdit} validationErrors={validationErrors}/>
        },
        {
            key: '4',
            label: <span className="Subtitle">{criteria?.criterias[2].name}</span>,
            children: <Presentation title={catTitle} scored={currentScore} criteria={criteria} handleBack={handleBack} handleFormDataChange={handleFormDataChange} handleSubmit={handleSubmit} handleEditSubmit={handleEditSubmit} currentScore={currentScore} handleEdit={handleEdit} validateFormData={validateFormData} validationErrors={validationErrors} formData={formData} setActiveKey={setActiveKey}/>
        },
    ];
    return (
        <>
            <Tabs
                activeKey={activeKey}
                onChange={setActiveKey}
                items={items}
                tabBarStyle={{pointerEvents: 'none'}}
            />
        </>
    )
}

export default TabScoring;